import gql from 'graphql-tag';

const sucursalesTableQuery = gql`
    query sucursales($whereCondition:SucursalesWhereWhereConditions, $numberItems: Int!, $numberPage: Int!, $fieldOrder: String!, $Order: SortOrder!) {
        sucursales(where:$whereCondition, first: $numberItems, page: $numberPage, activo: true, orderBy:[{field: $fieldOrder, order: $Order}]) {
            data {
                id,
                nombre,
                cliente_id,
                empresa_id,
                estado_id,
                direccion,
                codigo_postal,
                cp_id,
                municipio_id,
                descripcion,
                coordenadas,
                distancia_checada,
                colonia_id,
                empresa{
                    nombre
                }
            }
            paginatorInfo {
                count
                currentPage
                firstItem
                lastItem
                hasMorePages
                lastPage
                perPage
                total
            }
        }
    }
`

const queries = { sucursalesTableQuery };

export default queries;
