import Vue from "vue";

export default {
  getEstados(parametros) {
    return Vue.axios
      .get("catalogo/estados/find", { params: parametros })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getMunicipios(parametros) {
    return Vue.axios
      .get("catalogo/direccion/municipio/find", { params: parametros })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getCiudades(parametros) {
    return Vue.axios
      .get("catalogo/ciudades/find", { params: parametros })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getCP(parametros) {
    return Vue.axios
      .get("catalogo/direccion/codigo-postal/find", { params: parametros })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getColonias(parametros) {
    return Vue.axios
      .get("catalogo/direccion/colonia/find", { params: parametros })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getColores(parametros) {
    return Vue.axios
      .get("catalogo/colores/find", { params: parametros })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getEstudios(parametros) {
    return Vue.axios
      .get("catalogo/nivel-estudios/find", { params: parametros })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },
  getCodigoPostal(id) {
    return Vue.axios
      .get("catalogo/direccion/codigo-postal/" + id)
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  addColonia(parametros){
    return Vue.axios
      .post("catalogo/direccion/colonia", parametros )
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

};
