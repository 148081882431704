<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>
        <v-container xl fluid v-else>
            <v-row>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tabla"
                        :tableName="'Sucursales'"
                        :columns="columns"
                        :items="tableData.data"
                        :paginationInfo="tableData.paginatorInfo"
                        @pagination="getPaginationInfo"
                        :showAdvancedFilters="true"
                        @loadModalData="loadModalData"
                        :filters="filters"
                        @setFilters="setFilters"
                        :perPage="[10,25,50,100]"
                        :loading="isLoading"
                    >
                        <template slot="actionButtonsLeft">
                            <div class="botonAgregar">
                                <v-btn
                                    rounded
                                    color="#004BAF"
                                    class="btnAdd"
                                    right
                                    @click="abrirModal('add')"
                                >
                                    <v-icon dark class="mr-2">add</v-icon>Agregar
                                    <div class="mr-4"></div>
                                </v-btn>
                            </div>
                        </template>
                        <template slot="filters">
                            <v-col
                                xs="12"
                                sm="12"
                                md="12"
                                class="py-0"
                            >
                                <v-text-field
                                outlined
                                label="Nombre"
                                v-model="nombre"
                                ></v-text-field>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="estados"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Estado"
                                    persistent-hint
                                    v-model="estado_value"
                                    no-data-text="Datos no disponibles"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="municipios"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Municipio"
                                    persistent-hint
                                    v-model="municipio_value"
                                    no-data-text="Datos no disponibles"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                    no-data-text="Datos no disponibles"
                                >
                                </v-autocomplete>
                            </v-col>
                            <v-col v-if="rol == 'root' || rol == 'admin'" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                    no-data-text="Datos no disponibles"
                                >
                                </v-autocomplete>
                            </v-col>
                         </template>
                        <tbody slot="body" slot-scope="{ data }">
                            <tr :key="item.id" v-for="item in data">
                                <td >
                                    <div class="tblPrincipal" >{{ item.nombre }}</div>
                                </td>
                                <td>
                                    <div v-if="item.empresa" class="tblPrincipal">{{ item.empresa.nombre}}</div>
                                </td>
                                <td>
                                  <div class="tblPrincipal">{{ item.direccion}}</div>
                                </td>
                                <td>
                                    <div class="tblOpciones">
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn 
                                                    v-on="on"
                                                    class="botonHover" 
                                                    icon 
                                                    fab 
                                                    dark 
                                                    small
                                                    @click="abrirModal('update', item)"
                                                >
                                                    <v-icon class="tamIconoBoton invertirColorBotones">edit</v-icon>
                                                </v-btn>
                                            </template>
                                            <span>Editar</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn 
                                                v-on="on" 
                                                class="botonHover" 
                                                icon 
                                                fab 
                                                dark 
                                                small
                                                @click="goToDocumentos(item)"
                                            >
                                                <v-icon class="tamIconoBoton invertirColorBotones">book</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Documentos</span>
                                        </v-tooltip>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on }">
                                            <v-btn 
                                                v-on="on" 
                                                class="botonHover" 
                                                icon 
                                                fab 
                                                dark 
                                                small
                                                @click="eliminar(item.id, item.nombre)"
                                            >
                                                <v-icon class="tamIconoBoton invertirColorBotonesDelete">delete</v-icon>
                                            </v-btn>
                                            </template>
                                            <span>Eliminar</span>
                                        </v-tooltip>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>
        </v-container>

        <template>
            <v-layout row justify-center>
                <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModal"></v-btn>
                    </template>
                    <perfect-scrollbar style="background: #fff">
                        <v-card elevation="0">
                            <ValidationObserver ref="form" style="background-color:#fff;" v-slot="{ invalid }">
                                <v-card-title class="justify-center card_titulo">
                                    <div class="headerModal">
                                        <img :src="imagen" class="imgModal" />
                                        <h2 class="titleModal">{{ titulo_modal }}</h2>
                                    </div>
                                </v-card-title>

                                <v-card-text class="card_texto"> 
                                    <div id="loadingModal" v-show="!finaliza_carga">
                                        <div id="loading">
                                            <v-progress-circular
                                                indeterminate
                                                :size="100"
                                                :width="7"
                                                color="blue"
                                            ></v-progress-circular>
                                        </div>
                                    </div>

                                    <v-container v-show="finaliza_carga" grid-list-md id="contenedor">
                                        <v-divider class="divider"></v-divider>
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Nombre" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        autofocus
                                                        id="primero"
                                                        label="Nombre"
                                                        v-model="sucursal.nombre"
                                                        :error-messages="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <template v-if="rol == 'root'">
                                                <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Cliente" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="clientes"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Cliente"
                                                            persistent-hint
                                                            v-model="cliente_value"
                                                            :error-messages="errors"
                                                            no-data-text="Datos no disponibles"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="empresas"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Empresa"
                                                            persistent-hint
                                                            v-model="empresa_value"
                                                            :error-messages="errors"
                                                            no-data-text="Datos no disponibles"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                            </template>

                                            <template v-if="rol == 'admin'">
                                                <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Empresa" rules="required">
                                                        <v-autocomplete
                                                            outlined
                                                            :items="empresas"
                                                            item-text="nombre"
                                                            item-value="id"
                                                            label="Empresa"
                                                            persistent-hint
                                                            v-model="empresa_value"
                                                            :error-messages="errors"
                                                            no-data-text="Datos no disponibles"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>
                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                                                        <v-text-field
                                                            outlined
                                                            label="Código postal"
                                                            v-model="busca_cp"
                                                            :error-messages="errors"
                                                            @keyup.enter="busca_codigos()"
                                                            v-on:keydown.tab="busca_codigos()"
                                                            v-mask="'#####'"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>

                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <v-text-field
                                                        outlined
                                                        label="Estado"
                                                        v-model="estado"
                                                        disabled
                                                    />
                                                </v-col>

                                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                    <v-text-field
                                                        outlined
                                                        label="Municipio"
                                                        v-model="municipio"
                                                        disabled
                                                    />
                                                </v-col>
                                            </template>
                                            <template v-if="rol == 'root' || rol == 'admin-empresa'">
                                                <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                                    <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                                                        <v-text-field
                                                            outlined
                                                            label="Código postal"
                                                            v-model="busca_cp"
                                                            :error-messages="errors"
                                                            @keyup.enter="busca_codigos()"
                                                            v-on:keydown.tab="busca_codigos()"
                                                            v-mask="'#####'"
                                                        />
                                                    </ValidationProvider>
                                                </v-col>

                                                <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                                    <v-text-field
                                                        outlined
                                                        label="Estado"
                                                        v-model="estado"
                                                        disabled
                                                    />
                                                </v-col>

                                                <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                                    <v-text-field
                                                        outlined
                                                        label="Municipio"
                                                        v-model="municipio"
                                                        disabled
                                                    />
                                                </v-col>
                                            </template>
                                            <v-col cols="12" xs="12" sm="6" md="8" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Dirección" rules="required">
                                                    <v-text-field
                                                        outlined
                                                        label="Dirección"
                                                        v-model="sucursal.direccion"
                                                        required
                                                        :error-messages="errors"
                                                    />
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="4" class="py-0">
                                                <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                                                    <v-autocomplete
                                                        outlined
                                                        :items="colonias"
                                                        item-text="nombre"
                                                        item-value="id"
                                                        label="Colonia"
                                                        persistent-hint
                                                        v-model="sucursal.colonia_id"
                                                        :error-messages="errors"
                                                        no-data-text="Datos no disponibles"
                                                    >
                                                        <template v-if="mColonia"  v-slot:append-item>
                                                            <v-list-item>             
                                                                <v-list-item-content class="cursor-pointer"  @click="abrirModalColonia()">
                                                                    <v-list-item-title style="color:#007EFF">Agregar colonia</v-list-item-title>
                                                                </v-list-item-content>
                                                            </v-list-item>
                                                        </template>
                                                    </v-autocomplete>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="12" md="6" class="py-0">
                                                <v-text-field
                                                    outlined
                                                    label="Descripción"
                                                    v-model="sucursal.descripcion"
                                                />
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0 d-flex">
                                                <ValidationProvider v-slot="{ errors }" name="Distancia mínima para checar" rules="numeros_positivos_sin_cero">
                                                    <v-text-field
                                                        outlined
                                                        label="Distancia mínima para checar"
                                                        v-mask="'######'"
                                                        v-model="sucursal.distancia_checada"
                                                        hint="Distancia en metros"
                                                        :error-messages="errors"
                                                    >
                                                    </v-text-field>
                                                </ValidationProvider>
                                                <v-tooltip top class="pb-7">
                                                    <template v-slot:activator="{ on }">
                                                        <v-icon color='#96999A' v-on="on" slot="activator" class="pb-7 pl-2">help_outline</v-icon>
                                                    </template>
                                                    <span class="pb-7">La configuración que realices en este apartado se verá reflejado en los puntos donde el 
                                                        <br> usuario realice chequeo facial desde la aplicación móvil.</span>
                                                </v-tooltip>
                                            </v-col>
                                            
                                            <div style="margin-bottom: 15px;" v-show="sucursal.distancia_checada > 0">
                                                <transition  name="slide" mode="out-in">
                                                    <div class="row px-0 mx-0 pb-4">
                                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" >
                                                            <v-btn class="btnUbicacion d-flex pl-3 pr-9" elevation="0" @click="addMarker()">
                                                                <v-icon class="tamIcono outlined_v_icon d-flex justify-start" style="margin-inline-end:auto;">add_location_alt</v-icon>
                                                                Agregar ubicación
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="d-flex justify-start align-center">
                                                            <span style="font-size: 11px; color: grey;">
                                                                Haz clic en el marcador para eliminarlo.
                                                            </span>
                                                        </v-col>
                                                    </div>
                                                </transition>

                                                <transition v-show="sucursal.distancia_checada > 0" name="slide" mode="out-in">
                                                    <div class="row px-0 mx-0">
                                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                                            <GmapMap
                                                                ref="mymap"
                                                                :center="coordenadas"
                                                                :zoom="14"
                                                                :options="{ streetViewControl: false }"
                                                                map-type-id="roadmap"
                                                                style="width: 500px; height: 300px"
                                                            >
                                                                <gmap-marker
                                                                    v-for="(coordenada, index) in arrCoordenadas"
                                                                    :key="index"
                                                                    :position="coordenada"
                                                                    :title="'Ubicación #' + (index + 1)"
                                                                    :draggable="true"
                                                                    @click="deleteMarker(index)"
                                                                    @dragend="updateCoordinates($event.latLng, index)"
                                                                />
                                                            </GmapMap>
                                                        </v-col>
                                                    </div>
                                                </transition>
                                            </div>
                                        </v-row>
                                    </v-container>
                                </v-card-text>

                                <v-card-actions class="d-flex justify-end card_accion">
                                    <button
                                        class="btnCerrar"
                                       @click="cerrarModal()" 
                                       :disabled="loading_guardar"
                                    >
                                        Cancelar
                                    </button>
                                    <v-btn
                                        class="btnGuardar"
                                        @click="guardar()" 
                                        :disabled="invalid" 
                                        :loading="loading_guardar"
                                    >
                                        Guardar
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </perfect-scrollbar>
                </v-dialog>
            </v-layout>
        </template>

        <template class="borde-card">
            <v-dialog v-model="dialogColonia" persistent max-width="500px" class="borde-card">
                <template v-slot:activator="{ on }">
                    <v-btn v-on="on" class="botonModal" id="btnModalColonia" :style="'display: none;'"></v-btn>
                </template>
                <v-card class="borde-card">
                    <ValidationObserver ref="formColonia" v-slot="{ invalid }">
                        <v-card-title class="card_titulo padding_izquierdo padding_derecho">
                            <div class="headerModalStart">
                                <h2 class="titleModal_700_18">Agregar colonia</h2>
                            </div>
                        </v-card-title>
                        <v-card-text class="card_texto mt-5">
                            <div id="padre" v-show="isLoadingModalColonia">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingModalColonia" grid-list-md id="contenedor">
                                <v-row class="mt-0">
                                    
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Código postal" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Código postal" 
                                                class="pa-0 ma-0" 
                                                v-model="busca_cp" 
                                                :error-messages="errors" 
                                                required
                                                disabled
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pt-0 pb-0 ma-0">
                                        <ValidationProvider v-slot="{ errors }" name="Colonia" rules="required">
                                            <v-text-field 
                                                outlined
                                                label="Colonia" 
                                                class="pa-0 ma-0" 
                                                v-model="nombreColonia" 
                                                :error-messages="errors" 
                                                required
                                            >
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions class="d-flex justify-end card_accion">
                            <button
                                class="btnCerrar"
                                @click="cerrarModalColonia()" 
                                :disabled="isSavingColonia"
                            >
                                Cancelar
                            </button>
                            <v-btn
                                class="btnGuardar"
                                @click="guardarColonia()" 
                                :disabled="invalid" 
                                :loading="isSavingColonia"
                            >
                                Guardar
                            </v-btn>
                        </v-card-actions>
                    </ValidationObserver>
                </v-card>
            </v-dialog>
        </template>
    </div>
</template>

<script>
import sucursalApi from "../api/sucursales";
import catalogoApis from "../api/catalogos";
import clientesAPi from "../api/clientes";
import empresaApi from "../api/empresas";
import Notify from "@/plugins/notify";
import Datatable from '@/components/g-datatable/Datatable.vue';
import PerfectScrollbar from "perfect-scrollbar";
import queries from '@/queries/Sucursales';

export default {
    components: {
        "data-table": Datatable,
    },
    name: "Sucursales",
    data() {
        return {
            url: "sucursales/find",
            columns: [
                {
                label: "Nombre",
                name: "nombre",
                filterable: true,
                },
                {
                label: "Empresa",
                name: "nombre_empresa",
                filterable: false,
                },
                {
                label: "Dirección",
                name: "direccion",
                filterable: false,
                },
                {
                label: "Opciones",
                name: "opciones",
                align: "center",
                filterable: false,
                },
            ],
            filters: {},
            loading_guardar: false,
            dialog: false,
            datosLogin: "",
            sucursal: {
                id: 0,
                nombre: "",
                direccion: "",
                estado_id: null,
                municipio_id: null,
                cp_id: null,
                colonia_id: null,
                descripcion: "",
                empresa_id: null,
                cliente_id: null,
                distancia_checada: null,
                coordenadas: null,
            },
            arrCoordenadas: [],
            coordenadas: {
                lat: 19.00174,
                lng: -99.24221,
            },
            empresas: [],
            codigosP: [],
            colonias: [],
            titulo_modal: "",
            finaliza_carga: false,
            parametros_base: { activo: true, paginate: false, order: "asc" },
            accion: "",
            rol: "",
            clientes: [],
            municipios:[],
            estados:[],
            nombre:null,
            cliente_value: null,
            empresa_value: null,
            estado_value: null,
            municipio_value: null,
            municipio_id: null,
            codigo_postal_id: null,
            parametros_final: 0,
            indexMarker: null,
            imagen: "/static/modal/sucursal.png",

            busca_cp: "",
            estado: "",
            municipio: "",
            show: false,
        
            tableData       : {},
            isLoading       : true,
            paginationData  : {
                numberItems : 10,
                numberPage  : 1
            },

            mColonia                : false,
            dialogColonia           : false,
            isLoadingModalColonia   : false,
            nombreColonia           : null,
            isSavingColonia         : false,
            dialogSueldo            : false,
        };
    },
    watch: {
        cliente_value: function(val) {
            this.cambiaCliente(val);
        },
        estado_value: function(val) {
            this.cambiaEstado(val);
        },
        busca_cp: function(val){
            if(val != null){
                if(val.length == 5){
                    this.busca_codigos();
                    this.mColonia = true;
                }
                else{
                    this.estado = "";
                    this.municipio = "";
                    this.colonias = [];
                    this.mColonia = false;
                }
            }
        }
    },
    methods: {
        /**
         * @method getPaginationInfo Método para la paginación
         * @description Método que sirve para la paginación de la tabla
         */
        getPaginationInfo(data) {
            switch(data.type) {
                case "page":
                    this.paginationData.numberPage = data.value
                    break;

                case "itemsToShow":
                    this.paginationData.numberItems = data.value
                    break;
            }

            this.isLoading = true;
            this.$apollo.queries.usuarios;
        },

        /**
         * @method busca_codigos Método que se ejecuta buscar el estado y la ciudad de acuedo al CP.
         * @description Método que se ejecuta buscar el estado y la ciudad de acuerdo al CP.
         */
        busca_codigos() {
            let self = this;
            let params = {
                activo: true,
                nombre: this.busca_cp,
                include_padres: true,
            };
            catalogoApis.getCP(params).then((response) => {
                let result = response.data;
                if (result.length == 0) {
                    Notify.ErrorToast("No se encontro el Código postal.");
                    this.estado = "";
                    this.municipio = "";
                    this.colonias = [];
                    this.mColonia   = false;
                    return;
                }
                this.estado = result[0].nomb_estado;
                this.municipio = result[0].nomb_mun;
                this.sucursal.estado_id = result[0].estado_id;
                this.sucursal.municipio_id = result[0].municipio_id;
                this.sucursal.cp_id = result[0].id;

                catalogoApis.getColonias({ codigo_postal_id: result[0].id, order: "asc", paginate: false }).then((response) => {
                    self.colonias = response.data;
                    if (self.accion == "update") {
                        self.finaliza_carga = true;
                    }
                })
                .catch((e) => {
                        Notify.ErrorToast(
                            "Se presento un problema al cargar las colonias."
                        );
                    return;
                });
            })
            .catch((e) => {
                console.log(e);
                Notify.ErrorToast(
                    "Ocurrió un error al cargar los estados y ciudades."
                );
            });
        },

        /**
         * @method getCP Este método sirve para el codigo postal.
         * @description Este método sirve para el codigo postal. Se envia el id.
         * @param {int} id Id de código postal.
         */
        async getCP(id) {
            await catalogoApis.getCodigoPostal(id).then((response) => {
                this.sucursal.busca_cp = response.nombre;
                this.busca_cp = response.nombre;
                this.busca_codigos();
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los codigos postales.");
            });
        },

        abrirModalColonia(){
            this.dialogColonia = true;
            this.isLoadingModalColonia = false;
        },
        cerrarModalColonia(){
            this.dialogColonia = false;
            this.nombreColonia = null;
            this.isSavingColonia = false;
        },
        guardarColonia(){
            this.$refs.formColonia.validate().then(success => {
                this.isSavingColonia = true;
                if(!success) {
                    this.isSavingColonia = false;
                    return;
                }
                
                let parametros = {
                    nombre : this.nombreColonia,
                    codigo_postal_id : this.sucursal.cp_id
                };
                
                catalogoApis.addColonia(parametros).then((response) => {
                    Notify.Success("Operación exitosa", "La calonia se agrego correctamnte.");
                    this.busca_codigos()
                    this.cerrarModalColonia();
                    this.sucursal.colonia_id = response.data.id;
                    this.$nextTick(() => {
                        this.$refs.formColonia.reset();
                    });
                })
                .catch(err => {
                    this.isSavingColonia = false;
                    if(typeof err.response.data.error === 'string'){
                        Notify.ErrorToast(err.response.data.error);
                    }else{
                        let error = Object.values(err.response.data.error);
                        let leyenda = ``;

                        for (var i = 0; i < error.length; i++) {
                            leyenda+= `* ` + error[i] + `\n`;
                        }
                        Notify.ErrorToast(leyenda);
                    }
                });
            });
        },

        /**
         * @method listar Método para listar en tabla
         * @description Este metodo sirve para listar las sucursales en la tabla segun sea el rol
         */
        async listar() {
            await this.resetValues();
            this.filters = {AND:[]};
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;

            this.getClientes();
            this.getEstados();

            if (this.rol == "admin-empresa") {
                this.empresa_value =  this.datosLogin.empresa_id;
                this.filters.AND.push({column:'CLIENTE_ID', value:this.datosLogin.cliente_id});
                this.filters.AND.push({column:'EMPRESA_ID', value:this.datosLogin.empresa_id});
            } 
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.filters = { AND:[{column:'CLIENTE_ID', value:this.datosLogin.cliente_id}]};
            }
        },

        /**
         * @method setFilters Método para el filtrado.
         * @description Este método se ejecuta cuando se le da click al botón "buscar". Se realiza la petición con base a los campos solicitados.
         */
        setFilters() {
            this.filters = {AND:[]};
            
            let cliente = this.cliente_value;
            let empresa = this.empresa_value;
            let estado = this.estado_value;
            let municipio = this.municipio_value;
            let nombre = this.nombre;
            
            this.isLoading = true;
            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                cliente = this.datosLogin.cliente_id;
                empresa = this.datosLogin.empresa_id;
            } 

            if(nombre != null && nombre != undefined && nombre != ""){
                this.filters.AND.push({column:'NOMBRE', operator:'ILIKE', value:"%"+nombre+"%"});
            }
            
            if(cliente != null && cliente != undefined && cliente != ""){
                this.filters.AND.push({column:'CLIENTE_ID', value:cliente})
            }

            if(empresa != null && empresa != undefined && empresa != ""){
                this.filters.AND.push({column:'EMPRESA_ID', value:empresa})
            }  
                
            if(estado != null && estado != undefined && estado != ""){
                this.filters.AND.push({column:'ESTADO_ID', value:estado})
            }  

            if(municipio != null && municipio != undefined && municipio != ""){
                this.filters.AND.push({column:'MUNICIPIO_ID', value:municipio})
            } 
            this.paginationData.numberPage = 1;
            this.$apollo.queries.sucursales.refetch();
            this.resetValues();
        },

        /**
         * @method loadModalData Método para cargar datos para el modal de busqueda.
         * @description Este método sirve para inicializar todos los campos antes de abrir el modal de busqueda.
         */
        async loadModalData() {
            await this.resetValues();
            await this.getEstados();

            if (this.rol == "root") {
                this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                this.cambiaCliente(this.datosLogin.cliente_id);
            }
            else{
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },

        /**
         * @method abrirModal Método para abrir el modal de sucursales
         * @description Método para abrir el modal de sucursal. Modal para agregar o actualizar una sucursal.
         * @param {string} accion La acción puede ser "add" o "update"
         * @param {object} item Objeto que esta en null si la acción es "add"; item tiene los datos de una sucursal para que se pinten en el modal.
         */
        async abrirModal(accion, item = null) {
            this.resetValues();
            this.accion = accion;
            this.finaliza_carga = false;

            if (this.rol == "root") {
                await this.getClientes();
            }
            else if (this.rol == "admin") {
                this.cliente_value = this.datosLogin.cliente_id;
                await this.cambiaCliente(
                this.datosLogin.cliente_id,
                this.empresa_value
                );
            }
            else {
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }

            $("#primero").focus();

            if (accion == "add") {
                this.titulo_modal = "Registrar sucursal";
                this.imagen = "/static/modal/empresaSucursalCreate.svg";
                setTimeout(function() {
                    document.getElementById("btnModal").click();
                }, 100);

                this.finaliza_carga = true;
            } else {
                this.titulo_modal = "Actualizar sucursal";
                this.imagen = "/static/modal/empresaSucursalUpdate.svg";
                setTimeout(function() {
                    document.getElementById("btnModal").click();
                }, 100);

                this.cliente_value          = item.cliente_id;
                this.empresa_value          = item.empresa_id;
                this.municipio_id           = item.municipio_id;
                this.sucursal.cliente_id    = item.cliente_id;
                this.sucursal.codigo_postal = item.codigo_postal;
                this.sucursal.colonia_id    = item.colonia_id;
                this.sucursal.cp_id         = item.cp_id;
                this.sucursal.descripcion   = item.descripcion;
                this.sucursal.direccion     = item.direccion;
                this.sucursal.empresa_id    = item.empresa_id;
                this.sucursal.estado_id     = item.estado_id;
                this.sucursal.id            = item.id;
                this.sucursal.nombre        = item.nombre;

                if (item.distancia_checada != null && item.coordenadas != null) {
                    this.sucursal.distancia_checada = item.distancia_checada;
                    this.arrCoordenadas = JSON.parse(item.coordenadas);

                    this.coordenadas.lng = parseFloat(this.arrCoordenadas[0].lng);
                    this.coordenadas.lat = parseFloat(this.arrCoordenadas[0].lat);
                } else {
                    this.arrCoordenadas.push(this.coordenadas);
                }
                this.busca_cp = item.cp_nombre;
                this.estado = item.nombre_estado;
                this.municipio = item.nombre_municipio;
                await this.getCP(item.cp_id);
                this.finaliza_carga = true;
            }

            let item_coordenadas = {
                lat: this.coordenadas.lat,
                lng: this.coordenadas.lng,
            };

            this.arrCoordenadas.push(item_coordenadas);
        },

        /**
         * @method addMarker Método para agregar un nuevo punto en el mapa.
         * @description Método que sirve para agregar un nuevo punto en el mapa.
         */
        addMarker() {
            let lat = this.arrCoordenadas[this.arrCoordenadas.length - 1].lat;
            let lng = this.arrCoordenadas[this.arrCoordenadas.length - 1].lng+0.003;
            let nuevaCordenada = { lat, lng }
            this.arrCoordenadas.push( nuevaCordenada );
        },

        /**
         * @method deleteMarker Método para eliminar un punto en el mapa.
         * @description Método para eliminar un punto en el mapa.
         * @param {int} index index del punto en el mapa.
         */
        deleteMarker(index) {
            if (this.arrCoordenadas.length != 1) {
                this.arrCoordenadas.splice(index, 1);
            }
        },

        /**
         * @method updateCoordinates Método para actualizar las coordenadas del mapa
         * @description Método para actualizar las coordenadas del mapa
         * @param {object} event Objeto con los datos del ultimo punto
         * @param {int} index index del punto en el mapa
         */
        updateCoordinates(event, index) {
            let item = {
                lat: parseFloat(event.lat().toFixed(5)),
                lng: parseFloat(event.lng().toFixed(5)),
            };

            this.arrCoordenadas.splice(index, 1, item);
        },

        /**
         * @method eliminar Método que elimina una sucursal
         * @description Método que elimina una sucursal
         * @param {int} id Id de la sucursal a eliminar
         * @param {string} sucursal Nombre de la sucursal a eliminar
         */
        eliminar(id, sucursal) {
            Notify.Alert(
                "¿Estás seguro de eliminar esta sucursal?",
                "Esta acción <b>puede afectar</b> algunas funcionalidades del sistema.",
                "Eliminar",
                () => {
                    this.isLoading = true;
                    sucursalApi.deleteSucursal(id).then((response) => {
                        this.listar();
                        this.$apollo.queries.sucursales.refetch();
                        this.respuesta_ok("La sucursal ha sido eliminada satisfactoriamente");
                    })
                    .catch((err) => {
                        this.isLoading = true;
                        console.log(err);
                        if(typeof err.response.data.error === 'string'){
                            Notify.ErrorToast(err.response.data.error);
                        }else{
                            let error = Object.values(err.response.data.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }

                            Notify.ErrorToast(leyenda);
                        }
                    });
                },
                null,
                true,
                true
            );
        },

        /**
         * @method getClientes Método para clientes
         * @description Método para hacer petición a la API de clientes
         */
        getClientes() {
            let parametros = { activo: true, paginate: false };
            clientesAPi.getClientes(parametros).then((response) => {
                this.clientes = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los clientes.");
            });
        },

        /**
         * @method getEstados Este metodo realiza la petición a la api de estados.
         * @description Este metodo realiza la petición a la api de estados.
         */
        async getEstados(){
            await catalogoApis.getEstados({activo:true,paginate:false,order:"asc"}).then((response) => {
                this.estados = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los estados.");
            });
        },

        /**
         * @method cambiaCliente Este método realiza la petición a la api de empresas.
         * @description Este método realiza la petición a la api de empresas, esta petición sirve para llenar el campo de empresas en la parte de los filtros.
         * @param {int} id id de cliente, sirve para enviar en los paramentros de la api de empresas.
         */
        cambiaCliente(id, empresa = null) {
            if (this.sucursal.cliente_id != id) {
                this.empresa_value = null;
                this.sucursal.empresa_id = null;
            }
            if(id == null){
                return;
            }
            let parametros = { activo: true, paginate: false, cliente_id: id };
            empresaApi.getEmpresas(parametros).then((response) => {
                this.empresas = response.data;
                if (this.sucursal.empresa_id != null) {
                    this.empresa_value = this.sucursal.empresa_id;
                }
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
            });
        },

        /**
         * @method cambiaEstado Método que se ejecuta para listar municipios
         * @description Método que se ejecuta para listar municipios
         * @param {int} id Id de los municipios.
         */
        cambiaEstado(id, municipio = null) {
            if (this.estado_value != id) {
                this.municipio_value = null;
            }
            let parametros = { activo: true, paginate: false, estado_id: id, order:"asc" };
            catalogoApis.getMunicipios(parametros).then((response) => {
                this.municipios = response.data;
            })
            .catch((err) => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los municipios.");
             });
        },

        /**
         * @method guardar Este método guarda los datos que se necesitan para el registro de una sucursal.
         * @description Este método guarda los datos que se necesitan para una sucursal.
         * El método se llama para crear o actulizar un registro para una sucursal. En caso de que la petición
         * sea correcta la tabla se actualiza.
         */
        guardar() {
            this.$refs.form.validate().then(success => {
                this.loading_guardar = true;
                if(!success) {
                    this.loading_guardar = false;
                    return;
                }

                this.sucursal.cliente_id = this.cliente_value;
                this.sucursal.empresa_id = this.empresa_value;

                this.sucursal.codigo_postal = "0";
                if (this.sucursal.distancia_checada > 0) {
                    if (this.arrCoordenadas.length == 0) {
                        this.loading_guardar = false;
                        Notify.Error(
                            "Nueva sucursal",
                            "Es necesario que coloques el marcador del mapa en la ubicación de la sucursal."
                        );
                        return;
                    } 
                    else {
                        this.sucursal.coordenadas = JSON.stringify(this.arrCoordenadas);
                    }
                }

                this.isLoading = true;
                if (this.accion == "add") {
                    if (this.rol == "admin-empresa") {
                        this.sucursal.empresa_id = this.datosLogin.empresa_id;
                    }

                    sucursalApi.addSucursal(this.sucursal).then((response) => {
                        this.respuesta_ok("Operación exitosa", "Se ha creado la sucursal satisfactoriamente.");
                        this.$apollo.queries.sucursales.refetch();
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.respuesta_error(err);
                    });
                } 
                else if (this.accion === "update") {
                    delete this.sucursal.busca_cp;
                    sucursalApi.updateSucursal(this.sucursal).then((response) => {
                        this.respuesta_ok("Operación exitosa", "Se ha realizado la actualización satisfactoriamente.");
                        this.$apollo.queries.sucursales.refetch();
                    })
                    .catch((err) => {
                        this.isLoading = false;
                        this.respuesta_error(err);
                    });
                }
            });
        },

        /**
         * @method cerrarModal Este método cierra el modal.
         * @description Este método cierra el modal en cuestion y llama al metodo resetValues() para resetear los valores.
         */
        cerrarModal() {
            this.dialog = false;
            this.resetValues();
        },

        /**
         * @method resetValues Este método resetea los valores.
         * @description Este método resetea los valores por defecto de modales y variables para tener un modal limpio a la hora de abrirlos de nuevo.
         */
        resetValues() {
            this.sucursal = {
                id: 0,
                nombre: "",
                direccion: "",
                estado_id: null,
                municipio_id: null,
                cp_id: null,
                colonia_id: null,
                descripcion: "",
                empresa_id: null,
                cliente_id: null,
                distancia_checada: null,
                coordenadas: null,
            };
            this.estado_value = null;
            this.cliente_value = null;
            this.empresa_value = null;
            this.municipio_id = null;
            this.codigo_postal_id = null;
            this.parametros_final = 0;
            this.loading_guardar = false;
            this.empresas = [];
            this.municipios = [];
            this.municipio_value = null;
            this.estado_value = null;
            this.nombre = null;
            this.arrCoordenadas = [];
            this.busca_cp = null;
            this.colonias = [];
            this.estado = "";
            this.municipio = "";

            this.mColonia   = false;
            this.$nextTick(() => {
                if(this.$refs.form != undefined || this.$refs.form != null){
                    this.$refs.form.reset();
                }                
            });
        },

        /**
         * @method respuesta_error Método para mensaje de errores
         * @description Método para mostrar los errores mediante una notificación.
         * @param {string, object} err El error puede ser de tipo string o de tipo objeto. 
         */
        respuesta_error(err) {
            this.loading_guardar = false;
            if(typeof err.response.data.error === 'string'){
                Notify.ErrorToast(err.response.data.error);
            }else{
                let error = Object.values(err.response.data.error);
                let leyenda = ``;

                for (var i = 0; i < error.length; i++) {
                    leyenda+= `* ` + error[i] + `\n`;
                }

                Notify.ErrorToast(leyenda);
            }
        },

        /**
         * @method respuesta_ok Método para mostrar un mensaje exitoso
         * @description Método para mostrar un mensaje de opercación exitosa.
         * @param {string} msj Mensaje para mostrar en modal. 
         */
        respuesta_ok(msj) {
            this.listar();
            this.cerrarModal();
            Notify.Success("Operación exitosa", msj);
        },

        /**
         * @method getGeoPosition Método para geo posición
         * @description Método para la geo posición
         */
        getGeoPosition(position) {
            this.coordenadas.lat = position.coords.latitude;
            this.coordenadas.lng = position.coords.longitude;

            this.arrCoordenadas.splice(0, 1, {
                lat: this.coordenadas.lat,
                lng: this.coordenadas.lng,
            });
        },

        /**
         * @method init Este método se ejecuta en el create
         * @description Este método se jecuta el create
         */
        init() {
            const elementScroll = document.querySelector("#scroll");
            if (elementScroll) {
                const ps = new PerfectScrollbar(elementScroll, {
                wheelSpeed: 2,
                wheelPropagation: true,
                minScrollbarLength: 20,
                suppressScrollY: true,
                });
            }
            /* $("tr th:nth-child(2)").css("width", "25%");
            $("tr th:nth-child(3)").css("width", "35%");
            $("tr th:nth-child(4)").css("width", "15%"); */
        },

        /**
         * @method redireccionar Método que redirecciona a una vista
         * @description Método que redirecciona a la vista dashboard si el no es root, admin, admin-empresa
         */
        redireccionar(){
            this.$router.push('/dashboard');
        },

        goToDocumentos(sucursal){
            this.$session.remove('documentosSucursal')
            this.$session.set("documentosSucursal",sucursal)
            this.$router.push('/documentos_activos')
        }
    },

    /**
     * @description create(). Se inician los valores que se requieren antes de montar todos los componentes.
    */
    created() {
        if (navigator.geolocation) {
            var options = {
                enableHighAccuracy: true,
                timeout: 10 * 1000 * 1000,
                maximumAge: 0,
            };

            navigator.geolocation.getCurrentPosition(
                this.getGeoPosition,
                () => console.error(err),
                options
            );
        } 
        else {
            alert("No se concedieron permisos de geolocalización para la sucursal.");
        }

        this.listar();
    },

    /**
     * @description mounted(). Se inician los valores que se requieren al montar los componentes en la vista.
    */
    mounted() {
        this.init();
        this.$apollo.queries.sucursales.skip = false;
    },

    /**
     * @name apollo:sucursales
     * @description GraphQL apollo, query sucursales, realiza la petición a sucursales y los datos se utilizan en el componente tabla. 
     */
    apollo: {
        sucursales: {
            query       : queries.sucursalesTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                this.isLoading =  true;
                return {
                    whereCondition     : this.filters,
                    numberItems : this.paginationData.numberItems,
                    numberPage  : this.paginationData.numberPage,
                    fieldOrder  : this.$refs.tabla.sortKey ? this.$refs.tabla.sortKey : 'nombre',
                    Order       : this.$refs.tabla.Order,
                }
            },
            skip() {
                return true
            },
            result({loading, error}) {
                this.isLoading  = false;
                this.tableData  = this.sucursales;
            }
        }
    },
};
</script>

<style scoped>
    .btnUbicacion {
        width: 232px !important;
        height: 48px;
        border-radius: 30px;
        text-transform: none;
        background:#E0E0E0 !important;
        color:#828282 !important;
    }
    .cursor-pointer{
      cursor:pointer;
    }
</style>
